import React, { useContext, Fragment } from 'react';

// types
import type {
  WCmsEventPageView,
  WPublicThemeV2View,
  WCmsEventView,
} from '@zola/svc-web-api-ts-client';

// utils
import { sortByDate, isSameDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';
import getNonHomeCmsHeroImg from 'components/publicWebsiteV2/util/getNonHomeCmsHeroImg';
import checkIsSafari from 'components/publicWebsiteV2/util/checkIsSafari';

// context
import { DeviceContext } from 'contexts/DeviceContext';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

// components
import CalendarIcon from 'components/publicWebsiteV2/pages/Schedule/components/CalendarIcon';
import DiscoBallIcon from 'components/publicWebsiteV2/pages/Schedule/components/DiscoBallIcon';
import EggsIcon from 'components/publicWebsiteV2/pages/Schedule/components/EggsIcon';
import HeartsIcon from 'components/publicWebsiteV2/pages/Schedule/components/HeartsIcon';
import PlateIcon from 'components/publicWebsiteV2/pages/Schedule/components/PlateIcon';
import TitleImgDesc from 'components/publicWebsiteV2/common/TitleImgDesc';
import WelcomeEventIcon from 'components/publicWebsiteV2/pages/Schedule/components/WelcomeEventIcon';
import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';

// styles
import { Container, EmptyStateMessage, IconContainer } from './Schedule.styles';

import DesktopEvent from './components/DesktopEvent';
import MobileEvent from './components/MobileEvent';

export type CommonIconProps = {
  style: {
    [key: string]: string;
  };
  strokeProps: {
    [key: string]: string | undefined;
  };
};

type ScheduleProps = {
  pageData?: WCmsEventPageView;
  inPreview?: boolean;
};

const Schedule: React.FC<ScheduleProps> = ({ pageData, inPreview }) => {
  const {
    state: {
      components: { styleCmsEntityBodyFont, cmsEntityComponentBodyFontValues },
      wedding,
    },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);

  const { title, description, events, header_image_url, images } = pageData || {};
  const sortedEvents = (events || [])
    // @ts-expect-error ts-migrate(2551) FIXME: Property 'public' does not exist on type 'WCmsEven...
    .filter(event => event.public)
    .sort((event1, event2) => sortByDate(event1?.start_at as string, event2?.start_at as string));
  const orientation =
    wedding?.public_theme_v2?.components?.CMS_EVENT?.option_type_values?.ORIENTATION?.value ||
    'HORIZONTAL';
  const isSinglePageLayout =
    wedding?.public_theme_v2?.layout_type ===
    (('SINGLE_PAGE' as unknown) as WPublicThemeV2View.LayoutTypeEnum);
  const shouldShowDesktopEventFormat = !isSinglePageLayout && (device?.isDesktop() || inPreview);
  const showEmptyStateMessage = events?.length === 0 && !description;
  const cmsHeaderImgUrl = getNonHomeCmsHeroImg(images);
  const inlineIconStyles = {
    color: `#${cmsEntityComponentBodyFontValues.color}`,
  };

  const iconVisibility =
    wedding?.public_theme_v2?.components?.CMS_EVENT?.option_type_values?.SHOW_ICONS?.value ||
    'FALSE';

  const getStrokeProps = (defaultWidth = true) => {
    return checkIsSafari()
      ? {}
      : {
          stroke: 'currentColor',
          strokeWidth: defaultWidth ? '0.3' : '0.2',
        };
  };

  const renderIcon = (event: WCmsEventView) => {
    if (iconVisibility === 'FALSE') return null;
    let IconComponent; // Context on strokeWidth: https://graphicdesign.stackexchange.com/a/157839
    switch ((event?.type as unknown) as string) {
      case 'WELCOME_EVENT':
        IconComponent = (
          <WelcomeEventIcon style={inlineIconStyles} strokeProps={getStrokeProps()} />
        );
        break;
      case 'REHEARSAL_DINNER':
        IconComponent = <PlateIcon style={inlineIconStyles} strokeProps={getStrokeProps()} />;
        break;
      case 'WEDDING':
      case 'CEREMONY':
      case 'RECEPTION':
        IconComponent = <HeartsIcon style={inlineIconStyles} strokeProps={getStrokeProps()} />;
        break;
      case 'AFTER_PARTY':
        IconComponent = (
          <DiscoBallIcon style={inlineIconStyles} strokeProps={getStrokeProps(false)} />
        );
        break;
      case 'BRUNCH':
        IconComponent = <EggsIcon style={inlineIconStyles} strokeProps={getStrokeProps()} />;
        break;
      default:
        IconComponent = <CalendarIcon style={inlineIconStyles} strokeProps={getStrokeProps()} />;
    }

    return <IconContainer>{IconComponent}</IconContainer>;
  };

  const StyledEmptyStateMessage = styleCmsEntityBodyFont(EmptyStateMessage);

  let dateIndexOffset = 0;
  const MaybePageAnimationWrapper = isSinglePageLayout ? PageAnimationWrapper : Fragment;

  const pageAnimationWrapperProps = isSinglePageLayout ? { index: 1 } : {};

  return (
    <>
      <TitleImgDesc
        title={title}
        url={cmsHeaderImgUrl || header_image_url}
        description={description}
      />
      {events && events.length > 0 && (
        <Container>
          {sortedEvents.map((event, i) => {
            const prevEvent = sortedEvents[i - 1];
            const shouldRenderDate =
              !prevEvent ||
              !isSameDateUtc(event?.start_at as string, prevEvent?.start_at as string);

            if (shouldRenderDate) dateIndexOffset += 1;
            const indexForAnimation = i + dateIndexOffset;
            return shouldShowDesktopEventFormat ? (
              <DesktopEvent
                event={event}
                shouldRenderDate={shouldRenderDate}
                orientation={orientation}
                renderIcon={renderIcon}
                indexForAnimation={indexForAnimation}
                key={event.id}
              />
            ) : (
              <MaybePageAnimationWrapper key={event.id} {...pageAnimationWrapperProps}>
                <MobileEvent
                  event={event}
                  shouldRenderDate={shouldRenderDate}
                  renderIcon={renderIcon}
                  key={event.id}
                  indexForAnimation={indexForAnimation + 1}
                />
              </MaybePageAnimationWrapper>
            );
          })}
        </Container>
      )}
      {showEmptyStateMessage && (
        <PageAnimationWrapper>
          <StyledEmptyStateMessage>
            We&apos;re still planning the weekend&apos;s events and will update this section soon.
          </StyledEmptyStateMessage>
        </PageAnimationWrapper>
      )}
    </>
  );
};

export default Schedule;
