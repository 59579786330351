import React, { useState, useEffect, useContext, useRef } from 'react';
import _debounce from 'lodash/debounce';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  useWebsiteThemeContext,
  WebsiteThemeContextProvider,
} from 'components/publicWebsiteV2/context';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import { DeviceContext } from 'contexts/DeviceContext';
import { FlyoutPanelContextProvider } from 'components/publicWebsiteV2/contexts/flyoutPanelContext';

import { ThemeProvider } from '@emotion/react';

// Components
import Home from 'components/publicWebsiteV2/pages/Home';
import InnerContainer from 'components/publicWebsiteV2/common/InnerContainer';
import Banner from 'components/publicWebsiteV2/common/Banner';
import HiddenWebsiteBanner from 'components/publicWebsiteV2/common/HiddenWebsiteBanner';
import FlyoutPanel from 'components/publicWebsiteV2/common/FlyoutPanel';
import SinglePageHeroContainer from 'components/publicWebsiteV2/common/SinglePageHeroContainer';
import Footer from 'components/publicWebsiteV2/common/Footer';

// Utils
import { renderAbsoluteAssets } from 'components/publicWebsiteV2/util/renderHelpers';
import {
  mapBackgroundImagesToCss,
  mapBackgroundToInlineStyle,
} from 'components/publicWebsiteV2/util/mappers';
import { mapWeddingToComponents } from 'components/publicWebsiteV2/util/mappers/mapWeddingToComponents';
import { pickDeviceProperties } from 'components/publicWebsiteV2/util/getRelativeImgSize';
import { AnimationToggleBanner } from 'components/publicWebsiteV2/common/AnimationToggleBanner/AnimationToggleBanner';
import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import transformMediaQuery from './helpers/transformMediaQuery';
import transformWeddingThemeComponents from './helpers/transformWeddingThemeComponents';

// Styles
import {
  Container,
  RightPanel,
  RightPanelScrollable,
  SinglePageNamesModule,
  StyledNamesModule,
  getNamesModuleSpacing,
  StyledInnerContainer,
  FullHeightContainer,
  SectionContainer,
  PrimaryContentContainer,
  SectionDivider,
  ScrollDownArrow,
  HeaderSPContainer,
} from './SinglePageLayout.styles';
// Types
import type { ZolaTheme } from '../../../../ZolaThemeProvider';
import type { LayoutProps } from '../types';
import NavigationMenuIcon from '../../common/NavigationMenuIcon';
// Utils
import getPublicWebsiteHref from '../../util/getPublicWebsiteHref';
import getViewPercentage from './helpers/getViewPercentage';
import { getFontSizeOverride } from './helpers/responsiveFontSizeHelpers';

const Faqs = dynamic(() => import('../../pages/Faqs'));
const WeddingParty = dynamic(() => import('../../pages/WeddingParty'));
const Travel = dynamic(() => import('../../pages/Travel'));
const Schedule = dynamic(() => import('../../pages/Schedule'));
const ThingsToDo = dynamic(() => import('../../pages/ThingsToDo'));
const Gallery = dynamic(() => import('../../pages/Gallery'));
const Registry = dynamic(() => import('../../pages/Registry/RegistrySP'));
const Rsvp = dynamic(() => import('../../pages/Rsvp/RsvpSP'));

type SinglePageLayoutProps = LayoutProps & {
  previewContainerHeight?: number;
  isSamplePage?: boolean;
};

const SinglePageLayout = ({
  pageData,
  pageType,
  previewContainerHeight,
  isSamplePage,
}: SinglePageLayoutProps): JSX.Element => {
  const { state } = useWebsiteThemeContext();
  const {
    wedding,
    components: { globalHeaderFontValues },
    inPreview,
    enableInPreviewInteractions,
  } = state;

  const {
    owner_first_name,
    owner_last_name,
    partner_first_name,
    partner_last_name,
    public_theme_v2,
    nav_items,
    slug,
  } = wedding || {};
  const [overrideSection, setOverrideSection] = useState(pageType);
  const [visibleSection, setVisibleSection] = useState<string | undefined>();
  const [disableUpdating, setDisableUpdating] = useState(true);
  const [hasInitialScrolled, setHasInitialScrolled] = useState(false);

  const { device } = useContext(DeviceContext);

  // Ref of sections
  const sectionsRef = useRef<Map<string, HTMLDivElement> | null>(null);
  const scrollableRightPanelRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const getMap = () => {
    if (!sectionsRef.current) {
      // Initialize the Map on first usage.
      sectionsRef.current = new Map();
    }
    return sectionsRef.current;
  };

  const scrollToSection = (s: string) => {
    if (typeof window !== 'undefined') {
      const map = getMap();
      if (map) {
        const isMobile = device?.notDesktop() || (inPreview && inPreview === 'MOBILE');
        if (s === 'home' && isMobile) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          const node = map.get(s);
          if (node) {
            node.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }
      }
    }
  };

  const delayEnableUpdate = () =>
    setTimeout(() => {
      setDisableUpdating(false);
    }, 1000);

  useEffect(() => {
    const handleScroll = () => {
      const map = getMap();

      let sectionMostInView = overrideSection;
      let maxPercentageViewed = 0;
      // Calculate section in view
      map.forEach((node, currentSectionId) => {
        const currentViewPercentage = getViewPercentage(node);
        if (currentViewPercentage > maxPercentageViewed) {
          maxPercentageViewed = currentViewPercentage;
          sectionMostInView = currentSectionId;
        }
      });
      setVisibleSection(sectionMostInView);
    };
    let targetEle: EventTarget | undefined;
    if (device?.notDesktop()) {
      if (typeof window !== 'undefined') {
        targetEle = window;
      }
    } else if (scrollableRightPanelRef.current) {
      targetEle = scrollableRightPanelRef.current;
    }
    if (targetEle) {
      targetEle.addEventListener('scroll', _debounce(handleScroll, 300));
    }

    return () => {
      if (targetEle) {
        targetEle.addEventListener('scroll', _debounce(handleScroll, 300));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePathname = (section: string) => {
    if (typeof window !== 'undefined' && !inPreview) {
      const newUrl = getPublicWebsiteHref(slug, section, router?.query);
      // https://github.com/vercel/next.js/discussions/18072
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    }
  };

  useEffect(() => {
    setOverrideSection(pageType);
  }, [pageType]);

  useEffect(() => {
    const shouldScroll = inPreview ? enableInPreviewInteractions?.scroll : true;
    if (overrideSection && shouldScroll) {
      updatePathname(overrideSection);
      if (!hasInitialScrolled) {
        setTimeout(() => {
          scrollToSection(overrideSection);
          setHasInitialScrolled(true);
          delayEnableUpdate();
        }, 750);
      } else {
        scrollToSection(overrideSection);
        delayEnableUpdate();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overrideSection]);

  useEffect(() => {
    if (visibleSection && !disableUpdating) {
      updatePathname(visibleSection);
      // clear override section
      setOverrideSection('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleSection]);

  const handleExplicitNavClick = (s: string) => {
    if (inPreview) return;
    setDisableUpdating(true);
    setOverrideSection(s);
  };

  const globalBackground = public_theme_v2?.components?.GLOBAL?.background;
  const sectionDividerComponent = public_theme_v2?.components?.SECTION_DIVIDER;
  const welcomeNamesComponent = public_theme_v2?.components?.WELCOME_NAMES;

  const bodyContentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Using same container to approximate width for both asset components
  const { width: containerWidth } = useContainerDimensions(containerRef);
  // Only applied to names for single page
  const textAlignment =
    public_theme_v2?.components?.CONTENT_CONTAINER_HOME?.option_type_values?.TEXT_ALIGNMENT
      ?.value || 'CENTER';

  const adjustedMediaQuery = transformMediaQuery();
  // Update media query of emotion theme
  const adjustedTheme = (ancestorTheme: ZolaTheme) =>
    ({
      ...ancestorTheme,
      MEDIA_QUERY: adjustedMediaQuery,
    } as ZolaTheme);

  const getComponentByPageType = (p: string) => {
    switch (p) {
      case 'HOME':
        return (
          <Home
            pageData={pageData?.home}
            hideNames
            disableAssets
            handleExplicitNavClick={handleExplicitNavClick}
          />
        );
      case 'FAQ':
        return <Faqs pageData={pageData?.faq} />;
      case 'WEDDING_PARTY':
        return <WeddingParty pageData={pageData?.wedding_party} />;
      case 'EVENT':
        return <Schedule pageData={pageData?.event} inPreview={Boolean(inPreview)} />;
      case 'TRAVEL':
        return <Travel pageData={pageData?.travel} />;
      case 'POI':
        return <ThingsToDo pageData={pageData?.poi} />;
      case 'REGISTRY':
        return <Registry pageData={pageData?.registry} />;
      case 'RSVP':
        return <Rsvp pageData={pageData?.rsvp} />;
      case 'PHOTO':
        return <Gallery pageData={pageData?.photo} isSinglePage />;
      default:
        return null;
    }
  };

  // TODO: PUT IN HOOK SO DOESN'T UPDATE ON EVERY RE-RENDER
  const initialState = {
    ...state,
    components: mapWeddingToComponents({ wedding, mediaQuery: adjustedMediaQuery }),
    wedding: transformWeddingThemeComponents(wedding),
  };
  const reserveAssetSpacing =
    (welcomeNamesComponent?.absolute_assets_by_layout?.SINGLE_PAGE || []).length > 0;
  const getResponsiveNamesFontSize = (n: number): number => {
    const paddingX = n * (getNamesModuleSpacing(reserveAssetSpacing) / 100) * 2; // in vw units
    const remaining = n - paddingX; //  in vw units

    const getMaxWordLength = (s: string) => {
      const hyphenSplitArr = s.split('-');
      const splitArr = hyphenSplitArr.map(st => st.split(' ')).flat();
      const lengthArr = splitArr.map(w => w.length);
      return Math.max(...lengthArr);
    };
    const maxNameLength = Math.max(
      getMaxWordLength(owner_first_name || ''),
      getMaxWordLength(owner_last_name || ''),
      getMaxWordLength(partner_first_name || ''),
      getMaxWordLength(partner_last_name || '')
    );
    return remaining / maxNameLength;
  };
  const namesModuleFontSizeOverride = getFontSizeOverride(
    {
      desktop: Math.min(getResponsiveNamesFontSize(40), 5), // max 5vw
      tablet: Math.min(getResponsiveNamesFontSize(100), 9), // max 9vw
      mobile: Math.min(getResponsiveNamesFontSize(100), 9), // max 9vw
    },
    inPreview
  );

  return (
    <Container>
      <FlyoutPanelContextProvider>
        <HeaderSPContainer data-testid="HeaderSP" inPreview={inPreview}>
          <HiddenWebsiteBanner isDismissable />
          <AnimationToggleBanner />

          <Banner isSinglePage />
          <NavigationMenuIcon
            className="IcEnableChildrenOfContainer"
            navItems={nav_items}
            handleNavLinkClick={handleExplicitNavClick}
            previewContainerHeight={previewContainerHeight}
          />
        </HeaderSPContainer>
        <SinglePageHeroContainer
          pageData={pageData?.home}
          previewContainerHeight={previewContainerHeight}
          isSamplePage={isSamplePage}
        />
        <RightPanel
          overrideHeight={previewContainerHeight}
          css={
            (globalBackground?.background_images?.length || 0) > 1 &&
            mapBackgroundImagesToCss(globalBackground, adjustedMediaQuery)
          }
          style={
            (globalBackground?.background_images?.length || 0) < 2
              ? mapBackgroundToInlineStyle(globalBackground)
              : undefined
          }
          inPreview={Boolean(inPreview)}
          ref={containerRef}
        >
          <RightPanelScrollable
            ref={scrollableRightPanelRef}
            className="IcDisableChildrenOfContainer"
          >
            <ThemeProvider theme={adjustedTheme}>
              <WebsiteThemeContextProvider initialState={initialState}>
                <FullHeightContainer
                  overrideHeight={previewContainerHeight}
                  ref={node => {
                    const map = getMap();
                    if (node) {
                      if (map) {
                        map.set('home', node);
                      }
                    } else {
                      map.delete('home');
                    }
                  }}
                >
                  {renderAbsoluteAssets({
                    a: welcomeNamesComponent?.absolute_assets_by_layout?.SINGLE_PAGE,
                    device,
                    containerWidth,
                  })}
                  <StyledInnerContainer evenSpacing disableAssets isSinglePage>
                    <SinglePageNamesModule>
                      <StyledNamesModule
                        ownerFirstName={owner_first_name}
                        ownerLastName={owner_last_name}
                        partnerFirstName={partner_first_name}
                        partnerLastName={partner_last_name}
                        textAlignment={textAlignment}
                        disableAssets
                        fontSizeOverride={namesModuleFontSizeOverride}
                        reserveAssetSpacing={reserveAssetSpacing}
                      />
                      <ScrollDownArrow
                        width={32}
                        height={32}
                        style={{
                          color: `#${globalHeaderFontValues?.color}`,
                        }}
                        disableAnimation={Boolean(inPreview)}
                        inMobilePreview={inPreview === 'MOBILE'}
                        onClick={() =>
                          bodyContentRef?.current?.scrollIntoView({
                            behavior: 'smooth',
                          })
                        }
                      />
                    </SinglePageNamesModule>
                  </StyledInnerContainer>
                </FullHeightContainer>
                <PrimaryContentContainer ref={bodyContentRef}>
                  <InnerContainer disableAssets isSinglePage>
                    {nav_items?.map((n, i) => {
                      if (i > 0) {
                        const sectionId = ((n?.type as unknown) as string)?.toLowerCase();
                        // Add spacing above each section after 'home'
                        return (
                          <SectionContainer
                            addTopPadding
                            ref={node => {
                              const map = getMap();
                              if (node) {
                                if (map) {
                                  map.set(sectionId, node);
                                }
                              } else {
                                map.delete(sectionId);
                              }
                            }}
                            key={`SectionContainer--${i}`}
                          >
                            <PageAnimationWrapper>
                              <SectionDivider
                                containerWidth={containerWidth}
                                device={pickDeviceProperties(device)}
                                inFlowAsset={
                                  sectionDividerComponent?.in_flow_assets_by_layout?.SINGLE_PAGE
                                }
                              />
                            </PageAnimationWrapper>

                            {getComponentByPageType((n?.type as unknown) as string)}
                          </SectionContainer>
                        );
                      }
                      return (
                        <SectionDivider key={`SectionDivider--${i}`}>
                          {getComponentByPageType((n?.type as unknown) as string)}
                        </SectionDivider>
                      );
                    })}
                    <Footer pageType={pageType} />
                  </InnerContainer>
                </PrimaryContentContainer>
              </WebsiteThemeContextProvider>
            </ThemeProvider>
          </RightPanelScrollable>
        </RightPanel>
        <FlyoutPanel />
      </FlyoutPanelContextProvider>
    </Container>
  );
};

export default SinglePageLayout;
