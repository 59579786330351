import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';

import type {
  WRegistryCollectionItemView,
  WCmsZolaRegistryView,
} from '@zola/svc-web-api-ts-client';

import { mapFontColorToBgOverlayWithOpacity } from 'components/publicWebsiteV2/util/mappers';
import { isDarkColor } from 'pages/publicStyleUtils/utils.styles';
import { trackEvent } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { trackProductAdded } from '@zola-helpers/client/dist/es/tracking/eCommerce/productEvents';
import { getItemOrigin } from 'util/cartUtil';

import ZolaLogo from '@zola/zola-ui/src/components/ZolaLogo';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import { useAppSelector } from 'reducers/useAppSelector';
import { useAppDispatch } from 'reducers/useAppDispatch';
import { getRegistryCollections } from 'selectors/registryCollections/registryCollectionsSelectors';
import { getRegistryUrl } from 'selectors/registry/registrySelectors';
import { addItem, getCartSize } from 'actions/CartActions';
import { showModal } from 'actions/ModalActions';

import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import ZolaRegistryFilterBar from './ZolaRegistryFilterBar';
import {
  ZolaRegistryHeader,
  ZolaRegistryDetails,
  ZolaRegistryFooter,
  mapValuesToRegistryTileSerializedStyles,
  RegistryTileGrid,
  ConditionalRender,
  RegistryTileGridSP,
  ViewAllContainer,
} from './ZolaRegistry.styles';

const RegistryTile = dynamic(
  () => import('@zola/component-public-registry').then(module => module.RegistryTile),
  {
    loading: () => <div>loading</div>,
    ssr: false,
  }
);

export type AddToCartRequest = {
  skuId?: string;
  collectionItemId?: string;
  itemOrigin?: string;
  contribution?: number;
  quantity?: number;
};

export type ZolaRegistrySP = {
  isGuestViewing: boolean;
  zolaRegistry?: WCmsZolaRegistryView;
  isSinglePage?: boolean;
};

const ZolaRegistry: React.FC<ZolaRegistrySP> = ({ isGuestViewing, zolaRegistry, isSinglePage }) => {
  const {
    state: {
      components: {
        cmsEntityComponentBodyFontValues,
        globalAccentColor,
        CmsEntityBodyLink,
        backgroundColors,
        ThemedButton,
      },
      wedding,
      inPreview,
    },
  } = useWebsiteThemeContext();

  const registryCollections = useAppSelector(getRegistryCollections);
  const registryURL = useAppSelector(getRegistryUrl);
  const dispatch = useAppDispatch();

  const isDarkMode = isDarkColor(backgroundColors.content);
  const bodyFontColor = cmsEntityComponentBodyFontValues.color;
  const bodyFontFamily = cmsEntityComponentBodyFontValues.fontFamily;
  const bgOverlayColor = mapFontColorToBgOverlayWithOpacity(bodyFontColor);
  // TODO: CONVERT TO INLINE STYLE
  const StyledRegistryTile = styled(RegistryTile)`
    ${mapValuesToRegistryTileSerializedStyles(
      {
        backgroundOverlayColor: bgOverlayColor,
        textColor: bodyFontColor,
        textFont: bodyFontFamily,
        buttonColor: globalAccentColor,
        backgroundColor: backgroundColors.content,
      },
      isSinglePage
    )}
  `;

  const { slug } = wedding || {};

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.hash !== '') {
        // if we're returning from Registry, bring user to previous location
        const scrollY = Number(window.location.hash.substring(1));
        setTimeout(() => {
          window.scrollTo(0, scrollY);
        }, 1000);
      }

      if (isGuestViewing) {
        trackEvent('Registry Viewed', {
          location: 'REGISTRY_ON_WEDDING_WEBSITE',
          registry_id: zolaRegistry?.registry_object_id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: ADD MAX QUANTITY LOGIC
  const addToCart = (collectionItem: WRegistryCollectionItemView, quantity: number) => {
    if (inPreview) return;
    const addToCartRequest: AddToCartRequest = {
      skuId: collectionItem.sku_object_id,
      collectionItemId: collectionItem.object_id,
      itemOrigin: getItemOrigin(),
    };
    if (collectionItem.contributions?.group_gift) {
      addToCartRequest.contribution = quantity;
    } else {
      addToCartRequest.quantity = quantity;
    }
    dispatch(addItem(addToCartRequest))
      .then(res => {
        if (res) {
          const { cartId } = res.data;
          const {
            product_id,
            sku_object_id,
            name,
            brand,
            price,
            images,
            product_look_id,
          } = collectionItem;
          trackProductAdded({
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ cart_id: any; registry_id: str...
            cart_id: cartId,
            registry_id: zolaRegistry?.registry_object_id,
            product_id,
            sku: sku_object_id,
            name,
            brand: brand?.name,
            price,
            quantity,
            image_url: images?.[0]?.medium,
            variant: product_look_id,
            location: 'WEDDING_WEBSITE',
            section: 'QUICK_ADD',
          });
          if (typeof window !== 'undefined') {
            window.dispatchEvent(new CustomEvent('OPEN_MODAL_EVENT', { detail: 'CART' }));
            dispatch(getCartSize()).catch(() => undefined);
          }
        }
      })
      .catch(() => undefined);
  };

  const showProductDetails = (
    collectionItem: WRegistryCollectionItemView,
    isDefaultGiftCard: boolean,
    position: number
  ) => {
    if (inPreview) return;
    dispatch(
      showModal(
        'REGISTRY_ITEM',
        {
          collectionItem,
          isDefaultGiftCard,
          position,
          registryObjectId: zolaRegistry?.registry_object_id,
          useV2Tags: true,
        },
        {
          className: 'registry-item-modal',
          size: 'xl',
          bottomModalOnMobile: true,
        }
      )
    );
  };

  const determineRegistryUrl = (): string => {
    if (registryURL) {
      return registryURL;
    }
    if (slug) {
      return `/registry/${slug}${!isGuestViewing ? `?preview=true` : ''}`;
    }
    return '/wedding-registry';
  };

  const handleClickViewAll = (): void => {
    if (inPreview) return;
    if (typeof window !== 'undefined') {
      window.open(determineRegistryUrl(), '_blank', 'noopener, noreferrer')?.focus();
    }
  };

  const { defaultGiftCard, selectedCollectionObject, defaultCollection, otherCollections } =
    registryCollections || {};

  const isFilteredByCollection = selectedCollectionObject && (defaultCollection || []).length === 0;
  const selectedCollection = isFilteredByCollection
    ? otherCollections?.[0]?.registry_collection_items
    : defaultCollection;

  const collection = _cloneDeep(selectedCollection) || [];

  useEffectOnce(() => {
    // External Purchase Email
    const { splitOrderId, token, reservedQuantity, collectionItemId } = window
      ? queryString.parse(window.location.search)
      : { splitOrderId: null, token: null, reservedQuantity: null, collectionItemId: null };
    const isRedirectFromEmail = splitOrderId && token && reservedQuantity;
    // Check for external purchase email
    if (isRedirectFromEmail) {
      const externalItem = collection.find((collectionItem: WRegistryCollectionItemView) => {
        return collectionItem.object_id === collectionItemId;
      });
      if (externalItem) {
        showProductDetails(externalItem, false, 1);
      }
    }
  });

  // For SP only show the first 6 items in registry
  if (isSinglePage && collection && collection.length > 8) {
    collection.splice(selectedCollection && defaultGiftCard ? 7 : 8, collection.length - 1);
  }

  if (selectedCollection && defaultGiftCard) {
    // add gift card to end of purchasable items
    let index = _findIndex(
      collection,
      item =>
        item?.contributions?.show_as_fulfilled ||
        item?.contributions?.show_as_reserved ||
        !item?.actions?.add_to_cart
    );
    // if all available, place it in the end of the collection
    if (index < 0) {
      index = collection.length;
    }
    defaultGiftCard.button_cta = 'Choose amount';
    defaultGiftCard.detail_items = [];
    defaultGiftCard.contributions = {};
    collection.splice(index, 0, defaultGiftCard);
  }

  const renderRegistryFooter = () => (
    <ZolaRegistryFooter
      style={{
        backgroundColor: bgOverlayColor,
        fontFamily: bodyFontFamily,
        color: `#${bodyFontColor}`,
      }}
    >
      Need help finding the perfect gift from Zola Registry? Reach out to us at{' '}
      <CmsEntityBodyLink href="mailto:support@zola.com">support@zola.com</CmsEntityBodyLink> or{' '}
      <CmsEntityBodyLink href="tel:4086579652">1 (408) 657-ZOLA (9652)</CmsEntityBodyLink>.
    </ZolaRegistryFooter>
  );

  return isSinglePage ? (
    <div data-testid="ZolaRegistrySP">
      <PageAnimationWrapper index={1}>
        {collection && collection.length > 0 ? (
          <>
            <RegistryTileGridSP>
              {collection.map((collectionItem: WRegistryCollectionItemView, index: number) => {
                const isDefaultGiftCard =
                  collectionItem.type ===
                  (('GIFT_CARD' as unknown) as WRegistryCollectionItemView.TypeEnum);

                return (
                  <StyledRegistryTile
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onClick: () => void; collectionItemDetail:...
                    onClick={() => {
                      showProductDetails(collectionItem, isDefaultGiftCard, index);
                    }}
                    collectionItemDetail={collectionItem}
                    onAddToCart={addToCart}
                    key={`${collectionItem.object_id}-${index}`} // || collectionItem.id} <-- might be applicable to different registry item types in preview?
                    registryKey={slug}
                    isDefaultGiftCard={isDefaultGiftCard}
                    id={collectionItem.object_id} // || collectionItem.id}
                    compactButton={false}
                    disableBootstrapStyling
                    useV2Tags
                  />
                );
              })}
            </RegistryTileGridSP>
            <ViewAllContainer>
              <ThemedButton onClick={handleClickViewAll}>View all gifts</ThemedButton>
            </ViewAllContainer>
          </>
        ) : (
          renderRegistryFooter()
        )}
      </PageAnimationWrapper>
    </div>
  ) : (
    <div>
      <ZolaRegistryHeader>
        <ZolaLogo color={isDarkMode ? 'white' : ''} />
        <ZolaRegistryDetails style={{ color: `#${bodyFontColor}`, fontFamily: bodyFontFamily }}>
          <ConditionalRender
            as={CmsEntityBodyLink}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; as: StyledComponent<Anch...
            href="/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            About Zola
          </ConditionalRender>
          <CmsEntityBodyLink href="tel:4086579652">1-408-657-ZOLA</CmsEntityBodyLink>
          <ConditionalRender>Free Shipping Every Day</ConditionalRender>
        </ZolaRegistryDetails>
      </ZolaRegistryHeader>
      <ZolaRegistryFilterBar collection={collection} isDarkMode={isDarkMode} />
      {collection && collection.length > 0 && (
        <RegistryTileGrid>
          {collection.map((collectionItem: WRegistryCollectionItemView, index: number) => {
            const isDefaultGiftCard =
              collectionItem.type ===
              (('GIFT_CARD' as unknown) as WRegistryCollectionItemView.TypeEnum);

            return (
              <StyledRegistryTile
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onClick: () => void; collectionItemDetail:...
                onClick={() => {
                  showProductDetails(collectionItem, isDefaultGiftCard, index);
                }}
                collectionItemDetail={collectionItem}
                onAddToCart={addToCart}
                key={`${collectionItem.object_id}-${index}`} // || collectionItem.id} <-- might be applicable to different registry item types in preview?
                registryKey={slug}
                isDefaultGiftCard={isDefaultGiftCard}
                id={collectionItem.object_id} // || collectionItem.id}
                compactButton={false}
                disableBootstrapStyling
                useV2Tags
              />
            );
          })}
        </RegistryTileGrid>
      )}
      {renderRegistryFooter()}
    </div>
  );
};

export default ZolaRegistry;
